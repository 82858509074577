import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { GoogleReCaptchaProvider, IGoogleReCaptchaConsumerProps, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm, UseFormMethods } from 'react-hook-form'
import { gray, grayscale, orange as oldOrange } from 'src/styles/colors'
import AcceptTerms from '../../../AcceptTerms/OpenAccount'

import Whatsapp from '@interco/icons/build-v4/orangeds/MD/whatsapp'

import { initTheme, Theme } from '@interco/inter-ui'
import { Button } from '@interco/inter-ui/components/Button'
import { Input } from '@interco/inter-ui/components/Input'
import { apiForm } from 'src/config/api/axiosRequest'
import styled from 'styled-components'

interface IFormValues {
  nome: string;
  celular: string;
  cpf: string;
}

export type FormDataExternalPayload = {
  templateId: string;
  metadata: {
    categoria_conversa: string;
    matricula?: string;
    campanhaOrigem?: string;
  };
};

type TokenProps = {
  setIsToken?: Function;
  disabled: boolean;
  invertColors?: boolean;
}

type FormWhatsappProps = {
  onSuccessHandler: () => void;
  onErrorHandler: () => void;
  onSubmitHandler?: () => void;
  formTitle: string;
  formData: FormDataExternalPayload;
  apiUrl: string;
}

const InvertedColorsButton = styled.button<{fullWidth?: boolean; disabled?: boolean}>`
  background-color: ${(props: { disabled?: boolean }) => props.disabled ? `${gray[200]}` : '#fff'};
  color: ${(props: { disabled?: boolean }) => props.disabled ? `${grayscale[300]}` : `${oldOrange.extra}`};
  width: ${(props: { fullWidth?: boolean }) => props.fullWidth ? '100%' : 'auto'};
  border-radius: 8px;
  min-height: 48px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

const ErrorMessage = styled.p<{invertColors?: boolean}>`
  font-size: 12px;
  line-height: 15px;
  color: ${(props: { invertColors?: boolean }) => props.invertColors ? '#fff' : '#FF2A44'};
  margin-bottom: 0;
  padding-top: 0.25rem;
  text-align: right;
`

const SubmitButtonWithReCaptcha = ({ setIsToken, disabled, invertColors }: TokenProps) => {
  const { executeRecaptcha }: IGoogleReCaptchaConsumerProps = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('login_page')
    if (setIsToken && token) {
      setIsToken(token)
    }
  }, [ executeRecaptcha ])

  useEffect(() => {
    handleReCaptchaVerify()
  }, [ handleReCaptchaVerify ])

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const getIconColor = () => {
    if (disabled) return grayscale[300]
    if (invertColors) return oldOrange.extra

    return '#fff'
  }

  const ButtonVersion = invertColors ? InvertedColorsButton : Button

  return (
    <ButtonVersion
      fullWidth={true}
      disabled={disabled}
      onClick={handleReCaptchaVerify}
      type='submit'
    >
      Continuar pelo Whatsapp
      <Whatsapp
        height={24}
        width={24}
        color={getIconColor()}
        className='ml-2'
      />
    </ButtonVersion>
  )
}

const FormWhatsapp = ({ onSuccessHandler, onErrorHandler, onSubmitHandler, formTitle, formData, apiUrl }: FormWhatsappProps) => (
  <>
    <div>
      <h3 className='fs-24 lh-30 text-grayscale--500 mb-4'>
        {formTitle}
      </h3>
      <FormWhatsapp.FormComponent
        onSuccessHandler={onSuccessHandler}
        onErrorHandler={onErrorHandler}
        formData={formData}
        apiUrl={apiUrl}
        onSubmitHandler={onSubmitHandler}
      />
    </div>
  </>
)

type FormComponentProps = Omit<FormWhatsappProps, 'formTitle'> & {
  invertColors?: boolean;
}

FormWhatsapp.FormComponent = function FormComponent ({ onSuccessHandler, onErrorHandler, invertColors, formData, apiUrl, onSubmitHandler }: FormComponentProps) {
  const [ accept, setAccept ] = useState(false)
  const { register, errors, handleSubmit, setValue, watch }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ captchaToken, setCaptchaToken ] = useState<string | null>(null)
  const [ loading, setLoading ] = useState(false)

  const name = watch('nome')
  const celular = watch('celular')
  const cpf = watch('cpf')

  const submit = async (data: IFormValues) => {
    setLoading(true)

    const payload = {
      ...formData,
      userName: data.nome,
      to: `55${data.celular.replace(/\D/g, '')}`,
      clientSocialId: data.cpf.replace(/\D/g, ''),
      messageHandler: 'BABI',
      parameters: {
        PrimeiroNome: data.nome,
      },
      metadata: {
        ...formData.metadata,
        categoria_conversa: 'GR - Geral',
      },
    }

    try {
      await apiForm.post(`${apiUrl}`, [ payload ], {
        headers: {
          recaptchatoken: captchaToken,
        },
      })
      onSubmitHandler && onSubmitHandler()
      onSuccessHandler()
      setLoading(false)
    } catch {
      onErrorHandler()
      setLoading(false)
    }
  }
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className='row'>
        <div className='col-12 mb-3'>
          <Input
            className='w-100 pb-0'
            label='Nome'
            ref={register({
              required: 'Digite seu nome completo',
              validate: {
                isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
              },
            })}
            name='nome'
            id='nome'
            type='text'
            maxLength={100}
            placeholder='Digite seu nome'
            error={!!errors.nome}
          />
          {errors.nome && <ErrorMessage invertColors={invertColors}>{errors.nome.message}</ErrorMessage>}
        </div>
        <div className='col-12 mb-3'>
          <Input
            label='CPF'
            className='w-100 pb-0'
            ref={register({
              required: 'Insira um CPF válido',
              validate: {
                isCpf: (value: string) => Validations.cpf(value) || 'CPF ou CNPJ Inválido',
              },
            })}
            name='cpf'
            id='cpf'
            type='text'
            placeholder='Informe o CPF ou CNPJ'
            onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
            error={!!errors.cpf}
          />
          {errors.cpf && <ErrorMessage invertColors={invertColors}>{errors.cpf.message}</ErrorMessage>}
        </div>
        <div className='col-12 mb-3'>
          <Input
            label='Celular'
            className='w-100 pb-0'
            ref={register({
              required: 'Telefone obrigatório',
              pattern: {
                value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                message: 'Insira um número de celular válido',
              },
            })}
            name='celular'
            id='celular'
            type='tel'
            placeholder='Digite seu telefone'
            maxLength={15}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
            error={!!errors.celular}
          />
          {errors.celular && <ErrorMessage invertColors={invertColors}>{errors.celular.message}</ErrorMessage>}
        </div>
        <div className='col-12 mb-3'>
          <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' invertColors={invertColors} />
        </div>
        <div className='col-12'>
          <GoogleReCaptchaProvider
            reCaptchaKey={`${process.env.RECAPTCHA_SITE_KEY}`}
          >
            <SubmitButtonWithReCaptcha
              setIsToken={setCaptchaToken}
              invertColors={invertColors}
              disabled={!accept || loading || !name || !cpf || !celular}
            />
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </form>
  )
}

export default FormWhatsapp
