import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  .button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 17px;
    background: ${orange.extra};
    border-radius: 8px;
    color: ${white};
    font-weight: 700;

    @media ${device.desktopLG} {
      height: 49px;
      width: 360px;
    }
  }

  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 456px;
    }
  }
`

export const Title = styled.h2`
  display: block;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 50px;
  }
`
