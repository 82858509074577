import React from 'react'

import Img from 'gatsby-image'

import usePageQuery from '../../pageQuery'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as S from './style'

const TenhaAindaMaisBeneficios = () => {
  const imagesData = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.Section className='py-5' id='abertura-de-conta'>
      <div className='container py-md-5'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-5 col-lg-5 mb-4 mb-md-0 order-md-last'>
            <Img fluid={imagesData.bannerTenhaAindaMaisBeneficios.fluid} alt='Ilustração Super App Inter' />
          </div>
          <div className='col-12 col-md-6 col-xl-6 p-0'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500'>
              Tenha ainda mais benefícios com a Conta Digital Inter
            </h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Ainda não tem conta no Inter?
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 fw-400 text-grayscale--500'>
              Abra sua conta gratuita agora mesmo e aproveite tudo o que o Inter pode oferecer para simplificar ainda mais a sua vida!
            </p>
            <ul className='pl-4'>
              <li className='fs-16 fs-lg-18 text-grayscale--500'>Seguro para suas necessidades</li>
              <li className='fs-16 fs-lg-18 text-grayscale--500'>Shopping com muito dinheiro de volta na conta</li>
              <li className='fs-16 fs-lg-18 text-grayscale--500'>Cartão com zero anuidade</li>
              <li className='fs-16 fs-lg-18 text-grayscale--500'>Atendimento exclusivo INSS</li>
            </ul>
            <a
              href='https://intergo.app/4f677e05'
              target='_blank'
              rel='noreferrer'
              className='fs-14 fw-600 btn btn--lg btn-orange--extra text-none rounded-3 mt-3 mb-md-0 mw-100'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_09',
                  section_name: 'Tenha ainda mais benefícios com a Conta Digital Inter',
                  element_action: 'click button',
                  element_name: 'Abrir conta digital',
                  redirect_url: 'https://intergo.app/4f677e05',
                })
              }}
            >
              Abrir conta digital
            </a>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default TenhaAindaMaisBeneficios
